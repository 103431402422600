<template>
    <div class="row">
        <div class="col-12" v-if="!isLoad">
            <div v-if="exist" class="row">
                <div class="col-12" v-if="question.quest != null">
                    <form @submit.prevent="submitAnswer" class="card mb-5 d-block w-100 shadow-0 bg-transparent rounded-lg p-3 border-0 text-left question-div">
                        <div v-for="(item, i) in question.quest" :key="i" class="card-body p-4 bg-white rounded-lg mb-3">
                            <div class="bg-lightblue py-2 theme-dark-bg text-center" v-if="item.quest_type == 1">
                                <video class="w-50 h-auto" controls>
                                    <source :src=" item.path" type="video/mp4">
                                </video>
                            </div>
                            <div class="bg-lightblue py-2 theme-dark-bg" v-if="item.quest_type == 2">
                                <audio class="w-100" controls>
                                    <source :src=" item.path" type="audio/mpeg">
                                </audio>
                            </div>
                            <div style="min-height:25px !important" class="ql-editor my-3 bg-lightblue p-3 theme-dark-bg">
                                <div v-html="item.question"></div>
                            </div>
                            <div v-if="item.submission_type == 1">
                                <file-upload
                                class="btn btn-current mr-1"
                                @input="updateFile"
                                @input-file="inputFile"
                                :custom-action="fileAction"
                                :data="{index: i}"
                                :ref="setItemRef(i)" 
                                :input-id="setItemRef(i)" 
                                required>
                                <i class="fa fa-plus"></i>
                                Upload
                                </file-upload>
                                <a v-if="submission.answer[i]" :href=" submission.answer[i]" target="_blank" class="btn btn-success">Current File</a>

                                <button type="button" class="btn btn-danger ml-1"  v-if="!$refs[`upload${i}`] || $refs[`upload${i}`][0].active" @click.prevent="$refs[`upload${i}`][0].active = false">
                                    <i class="fa fa-stop" aria-hidden="true"></i>
                                    Stop Upload
                                </button>
                            </div>
                            <div v-if="item.submission_type == 2">
                                <vue-editor v-model="submission.answer[i]" />
                                <div class="clearfix"></div>
                            </div>
                            <div v-if="item.submission_type == 3">
                                <table class="table table-bordered text-center">
                                    <thead>
                                        <tr>
                                            <th v-for="(opt, j) in item.option" :key="j">{{opt.option}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td v-for="(opt, j) in item.option" :key="j">
                                                <span class="py-2 px-3 mr-2 d-inline-block rounded-circle text-white font-xssss fw-600 radio-cont">
                                                    <input v-model="submission.answer[i]" type="radio" :value="opt.id" :name="`radios[${i}]`">
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="item.submission_type == 4">
                                <div v-if="item.option?.length" class="bg-lightgrey theme-dark-bg py-3 px-2 mt-3 style2 rounded-lg font-xssss fw-600 lh-28 text-grey-700 mb-0 p-2">
                                    <div class="mx-auto" style="width: 95%;">
                                        <div class="text-center w-100">
                                            <input type="range" @change="checkRange(i)" :list="`tickmarks${index}`" v-model="submission.temp[i]" class="w-100" step="1" min="0" :max="item.option?.length ? item.option?.length -1 : 0">
                                            <datalist :id="`tickmarks${index}`" class="w-100">
                                                <option v-for="(item, index) in item.option" :key="index" :value="index" :label="item.option"></option>
                                            </datalist>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-3 bg-white rounded-lg mb-5 text-right">
                            <button type="submit" class="btn btn-lg btn-current fw-500">Save</button>
                        </div>
                    </form>
                </div>
                <div class="col-12" v-else>
                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8 text-center default-page">
                            <div class="card border-0 text-center d-block">
                                <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                                <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                                <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                                <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" v-else>
            <div class="row">
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import FileUpload from 'vue-upload-component'
export default {
    components:{
        FileUpload
    },
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            questionnaireId: this.$route.params.idQuestionnaire,
            media : process.env.VUE_APP_URL_CLOUD,
            exist: true,
            isLoad: true,
            question: [],
            index:-1,
            files:[],
            upload:{
                path:[]
            },
            submission:{
                id:[],
                answer:[],
                temp:[]
            }
        }
    },
    created(){
        this.getQuestionnaire()
    },
    methods: {
        checkRange(i){
            if(this.submission.temp[i]) {
                let index = this.submission.temp[i]
                let answer = this.question?.quest[i]?.option[index]
                if(answer) {
                    this.submission.answer[i] = answer.id
                }
            }
            
        },
        setItemRef(i) {
            return `upload${i}`;
        },
        async submitAnswer(){
            const submission = this.question.quest.map((item, i) => {
                return {
                    id: this.submission.id[i] ? this.submission.id[i] : null,
                    quest_id: item.id,
                    type: item.submission_type,
                    answer: this.submission.answer[i] ? this.submission.answer[i] : null,
                };
            })
            var data = {
                slug: this.paramsId,
                questionnaire_id: this.questionnaireId,
                submission: submission,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/core/reflection/questionnaire/quest/submit`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.getQuestionnaire()
                    this.$swal({
                        toast: true,
                        title: 'Rating Scale!',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.$router.push({name:'MyCourseReflectionRating', params:{idCourse: this.paramsId, idQuestionnaire: this.questionnaireId}})
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Rating Scale!',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async getQuestionnaire(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/reflection/questionnaire/quest?slug=${this.paramsId}&id=${this.questionnaireId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    this.question = res.data.data
                    this.question.quest.map((item, index) => {
                        if(item.ans != null) {
                            this.submission.id[index] = item.ans.id
                            this.submission.answer[index] = item.ans.answer
                            this.submission.temp[index] = item.ans.answer
                        } else {
                            if(item.submission_type == 4) {
                                this.submission.temp[index] = 0
                                this.submission.answer[index] = item?.option[0]?.id
                                this.submission.id[index] = ''
                            } else {
                                this.submission.temp[index] = ''
                                this.submission.answer[index] = ''
                                this.submission.id[index] = ''
                            }
                        }
                    })
                    console.log(this.submission)
                    this.isLoad = false
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Rating Scale!',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(err => {
                if (err.response.status == 404) {
                    this.isLoad = false
                    this.exist = false
                }
            })
        },
        updateFile(value) {
            this.files[value[0].data.index] = value[0]
        },
        async fileAction(file) {
            return this.postFile(file)
        },
        async inputFile(newFile, oldFile) {

            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs[`upload${newFile.data.index}`][0].active) {
                    this.$refs[`upload${newFile.data.index}`][0].active = true
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir', 'submission');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.submission.answer[file.data.index] = res.data.path
                return res.data
            })
        },
        async deleteFile(file){
            let data = new FormData();
            data.append('path', file.response.path);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files[file.data.index] = []
                this.submission.answer[file.data.index] = ''
                return res.data
            })
        },
    }
}
</script>
<style scoped>
    .radio-cont input[type='radio'] {
        transform: scale(2.2);
        visibility: hidden;
    }
    .radio-cont input[type='radio']:after {
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #d1d3d1;
        content: '';
        visibility: visible;
        border: none;
    }

    .radio-cont input[type='radio']:checked:after {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #ffa500;
        content: '';
        display: inline-block;
        visibility: visible;
        border: none;
    }
    datalist {
        display: flex;
        justify-content: space-between;
        color: #000;
        font-size: 14px;
    }
</style>


